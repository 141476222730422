"use strict";
import file from './clayers_progress.csv';
import './assets/images/images.js';
import menu_background from './assets/images/menu_background.png';
import './styles.css';

const page = document.querySelector('.state');
document.body.style.backgroundImage  = 'url(' + menu_background + ')';

async function checkImageExists(imagePath){

    const img = fetch(imagePath);

    return await fetch(imagePath, { method: "HEAD" }) 
    .then(response => response.ok) 
    .catch(error => { 
        console.log("An error occurred: ", error); 
    });
 }

function renderData(name, text, imagePath){
    const progressState = document.createElement('div');
    progressState.classList.add(`state__progress--${name}`, 'progress');

    const progressImg = document.createElement('div');
    progressImg.classList.add('progress__image');
    if (!!imagePath) {
        checkImageExists(imagePath).then((res) => {
            console.log(imagePath, res);
            if (res) {
                const img = document.createElement('img');
                img.src = imagePath;
                progressImg.appendChild(img);
            }
        });
    }
    progressState.appendChild(progressImg);

    const progressText = document.createElement('div');
    progressText.classList.add('progress__text');
    progressText.innerHTML = text;

    progressState.appendChild(progressText);

    page.append(progressState);
}

function renderTotal(name, text, value, imagePath, strokePath) {
    const progressState = document.createElement('div');
    progressState.classList.add(`state__progress--${name}`, 'progress');

    const progressImg = document.createElement('div');
    progressImg.classList.add('progress__image');
    if (!!imagePath && !!strokePath) {
        checkImageExists(strokePath).then((res) => {
            if (res) {
                checkImageExists(imagePath).then((res) => {
                    if (res) {
                        const img = document.createElement('img');
                        img.src = imagePath;
                        img.style.width = `${value}%`;
                        progressImg.appendChild(img);
                        progressImg.style.borderImage = `url(${strokePath}) 30 round`;
                    }
                })
            }
        });
    }
    progressState.appendChild(progressImg);

    const progressText = document.createElement('div');
    progressText.classList.add('progress__text');
    progressText.innerHTML = text;

    progressState.appendChild(progressText);

    page.prepend(progressState);
}

function readCSV() {
    const DELIMITER = ',';
    const NEWLINE = '\n'; 
    fetch(file)
    .then((res) => res.text())
    .then((text) => {
        if (!text) {
            throw Error('Empty file');
        }

        let totalValue = 0;
        let totalAmount = 0;
        let totalTitle = 'TOTAL';
        let totalImagePath = './assets/images/green_piece.png';
        let totalstrokePath = './assets/images/stroke.png';
        
        const rows = text.split(NEWLINE);
        rows.forEach((row, index) => {
            const [name, title, logged, estimated, imagePath, strokePath] = row.split(DELIMITER);
            if (!name || !title || ! logged || !estimated) return;

            const value = Math.floor(parseInt(logged)/parseInt(estimated) * 100);
            if (name === 'total') {
                totalImagePath = imagePath ? imagePath : totalImagePath;
                totalstrokePath = strokePath ? strokePath : totalstrokePath;;
                totalTitle = title;
                if (value >= 0) totalValue = value;
                return;
            }
            if (!(value >= 0)) return;

            const text = `${title} ${value}%`;

            renderData(name, text, imagePath);

            totalValue += value;
            totalAmount++;
        })
        if (totalAmount === 0) totalAmount = 1;
        const totalText = `${totalTitle} ${totalValue/totalAmount}%`;
        renderTotal('total', totalText, totalValue/totalAmount, totalImagePath, totalstrokePath);
        

    })
    .catch((e) => console.error(e));
}

readCSV();

